// import njLogo from '@newjersey/njwds/src/img/nj-logo-gray-20.png'
import { PUBLIC_ENV } from 'constants/environments'

import { useTranslation } from 'react-i18next'
import React from 'react'
import {
  GridContainer,
  Identifier,
  IdentifierGov,
  IdentifierIdentity,
  IdentifierLink,
  IdentifierLinkItem,
  IdentifierLinks,
  // IdentifierLogo,
  // IdentifierLogos,
  IdentifierMasthead,
} from '@trussworks/react-uswds'

import { Feedback } from 'components/Feedback/Feedback'

export const Footer = () => {
  const { t } = useTranslation('common', { keyPrefix: 'footer' })

  return (
    <footer role="contentinfo">
      <GridContainer className="usa-footer__return-to-top">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#">{t('return_top')}</a>
      </GridContainer>
      {PUBLIC_ENV.ENABLE_FEEDBACK && <Feedback />}
      <Identifier>
        <IdentifierMasthead aria-label={t('identifier_aria')}>
          {/* <IdentifierLogos>
            <IdentifierLogo href="">
              <img
                key="logo"
                src={njLogo.src}
                className="usa-identifier__logo-img"
                alt={t('logo_alt')}
              />
            </IdentifierLogo>
          </IdentifierLogos> */}
          <IdentifierIdentity domain="">
            {t('official')}
            <a href="https://www.usa.gov/unemployment-benefits">{t('state')}</a>
          </IdentifierIdentity>
        </IdentifierMasthead>
        <IdentifierLinks navProps={{ 'aria-label': t('links') }}>
          <IdentifierLinkItem key="gov">
            <IdentifierLink href="#">{t('gov')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="ltgov">
            <IdentifierLink href="#">{t('ltgov')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="home">
            <IdentifierLink href="#">{t('home')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="services">
            <IdentifierLink href="#">{t('services')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="departments">
            <IdentifierLink href="#">{t('departments')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="faqs">
            <IdentifierLink href="#">{t('faqs')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="contact">
            <IdentifierLink href="#">{t('contact')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="privacy">
            <IdentifierLink href="#">{t('privacy')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="legal">
            <IdentifierLink href="#">{t('legal')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="accessibility">
            <IdentifierLink href="#">{t('accessibility')}</IdentifierLink>
          </IdentifierLinkItem>
          <IdentifierLinkItem key="opra">
            <IdentifierLink href="#">{t('opra')}</IdentifierLink>
          </IdentifierLinkItem>
        </IdentifierLinks>
        <IdentifierGov aria-label={t('gov_aria')}>
          {t('copyright')}
        </IdentifierGov>
      </Identifier>
    </footer>
  )
}
