export const CLAIM_FORM_BASE_ROUTE = '/claim'

export const makeClaimFormRoute = (page: string) =>
  `${CLAIM_FORM_BASE_ROUTE}/${page}`

export const ExternalRoutes = () => {
  const env = process.env.NEXT_PUBLIC_APP_ENV
  const allEnvs = {
    CLAIM_STATUS: '#',
    CERTIFY: '#',
    HOW_TO_CERTIFY: '#',
    HOW_TO_REMAIN_ELIGIBLE: '#',
    ID_ME_VERIFY: '#',
    ID_ME_DOCUMENT_TYPES: '#',
    ID_ME_PROCESS: '#',
    TEMPORARY_DISABILITY_INSURANCE: '#',
    EMPLOYER_DISABILITY_INSURANCE: '#',
    WORKERS_COMP: '#',
    DOL_UNEMPLOYMENT_INFO:
      'https://www.dol.gov/general/topic/unemployment-insurance/',
    IN_PERSON_APPOINTMENT_LINK: '#',
    BENEFITS_CALCULATOR: '#',
    WEEKLY_BENEFIT_RATE_FAQ: '#',
    ABOUT_BENEFITS_AND_TAXES: '#',
    ABOUT_BENEFIT_PAYMENTS: '#',
    JPAY: '#',
    WHO_IS_ELIGIBLE: '#',
    APPEAL_PROCESS: '#',
    DIRECT_DEPOSIT_AUTHORIZATION: '#',
    DIRECT_DEPOSIT_AUTHORIZATION_EMAIL: 'mailto: fakey@fakedomain.com',
  }

  if (env === 'production') {
    return {
      ...allEnvs,
      UPDATE_PAYMENT_INFO: '#',
      TAX_DOCUMENTS: '#',
      UPDATE_CONTACT_INFO: '#',
      // AKA "SSI"
      LEGACY_APPLICATION: '#',
    }
  } else {
    // Using securest for all non-production environments. SSI may have a dev environment
    // at stclaimproxy.dol.state.nj.us, however we currently point to only the SSO Test
    // environment, so pointing to other Test environments makes the experience consistent,
    // and avoids confusing authentication flows.
    return {
      ...allEnvs,
      UPDATE_PAYMENT_INFO: '#',
      TAX_DOCUMENTS: '#',
      UPDATE_CONTACT_INFO: '#',
      // AKA "SSI"
      LEGACY_APPLICATION: '#',
    }
  }
}

export const Routes = {
  HOME: '/',
  SCREENER: '/screener',
  SCREENER_REDIRECT: '/screener-redirect',
  PRIVACY: '/privacy',
  MAINTENANCE: '/maintenance',
  ERROR_500: '/500',
  SIGNIN: '/signin',

  CERTIFY: ExternalRoutes().CERTIFY,
  CLAIM_STATUS: ExternalRoutes().CLAIM_STATUS,
  HOW_TO_CERTIFY: ExternalRoutes().HOW_TO_CERTIFY,
  HOW_TO_REMAIN_ELIGIBLE: ExternalRoutes().HOW_TO_REMAIN_ELIGIBLE,
  ID_ME_DOCUMENT_TYPES: ExternalRoutes().ID_ME_DOCUMENT_TYPES,
  ID_ME_PROCESS: ExternalRoutes().ID_ME_PROCESS,
  ID_ME_VERIFY: ExternalRoutes().ID_ME_VERIFY,
  LEGACY_APPLICATION: ExternalRoutes().LEGACY_APPLICATION,
  UPDATE_PAYMENT_INFO: ExternalRoutes().UPDATE_PAYMENT_INFO,
  TAX_DOCUMENTS: ExternalRoutes().TAX_DOCUMENTS,
  TEMPORARY_DISABILITY_INSURANCE:
    ExternalRoutes().TEMPORARY_DISABILITY_INSURANCE,
  EMPLOYER_DISABILITY_INSURANCE: ExternalRoutes().EMPLOYER_DISABILITY_INSURANCE,
  WORKERS_COMP_LINK: ExternalRoutes().WORKERS_COMP,
  UPDATE_CONTACT_INFO: ExternalRoutes().UPDATE_CONTACT_INFO,
  DOL_UNEMPLOYMENT_INFO: ExternalRoutes().DOL_UNEMPLOYMENT_INFO,
  BENEFITS_CALCULATOR_LINK: ExternalRoutes().BENEFITS_CALCULATOR,
  WEEKLY_BENEFIT_RATE_FAQ: ExternalRoutes().WEEKLY_BENEFIT_RATE_FAQ,
  ABOUT_BENEFITS_AND_TAXES: ExternalRoutes().ABOUT_BENEFITS_AND_TAXES,
  ABOUT_BENEFIT_PAYMENTS: ExternalRoutes().ABOUT_BENEFIT_PAYMENTS,
  JPAY_LINK: ExternalRoutes().JPAY,
  WHO_IS_ELIGIBLE: ExternalRoutes().WHO_IS_ELIGIBLE,
  APPEAL_PROCESS: ExternalRoutes().APPEAL_PROCESS,
  DIRECT_DEPOSIT_AUTHORIZATION: ExternalRoutes().DIRECT_DEPOSIT_AUTHORIZATION,
  DIRECT_DEPOSIT_AUTHORIZATION_EMAIL:
    ExternalRoutes().DIRECT_DEPOSIT_AUTHORIZATION_EMAIL,

  // Homepage
  IN_PERSON_APPOINTMENT_LINK: ExternalRoutes().IN_PERSON_APPOINTMENT_LINK,

  // Claim Form
  CLAIM: {
    START: makeClaimFormRoute('start'),
    PREQUAL: makeClaimFormRoute('prequal'),
    PERSONAL: makeClaimFormRoute('personal'),
    ADDRESS_VERIFICATION: makeClaimFormRoute('address-verification'),
    CONTACT: makeClaimFormRoute('contact'),
    DEMOGRAPHICS: makeClaimFormRoute('demographics'),
    IDENTITY: makeClaimFormRoute('identity'),
    RECENT_EMPLOYERS: makeClaimFormRoute('recent-employers'),
    EDIT_EMPLOYER: makeClaimFormRoute('edit-employer'),
    REVIEW_EMPLOYERS: makeClaimFormRoute('review-employers'),
    OCCUPATION: makeClaimFormRoute('occupation'),
    EDUCATION_VOCATIONAL_REHAB: makeClaimFormRoute('education-and-training'),
    UNION: makeClaimFormRoute('union'),
    DISABILITY: makeClaimFormRoute('disability'),
    PAYMENT: makeClaimFormRoute('payment'),
    REVIEW: makeClaimFormRoute('review'),
    SUCCESS: makeClaimFormRoute('success'),
  },
  REOPENER: {
    PAYMENT: '/reopener/[application_id]/payment',
    DISABILITY: '/reopener/[application_id]/disability',
    EDUCATION_VOCATIONAL_REHAB:
      '/reopener/[application_id]/education-and-training',
    EMPLOYMENT: '/reopener/[application_id]/employment',
    RECENT_EMPLOYERS: '/reopener/[application_id]/recent-employers',
    VERIFY_ADDRESS: '/reopener/[application_id]/verify-address',
    REVIEW: '/reopener/[application_id]/review',
    STEP_VERIFICATION: 'reopener/step-verification',
    START: 'reopener/start',
    SUCCESS: 'reopener/[application_id]/success',
  },
}
