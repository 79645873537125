import { Trans, useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import {
  Banner,
  BannerButton,
  BannerContent,
  // BannerFlag,
  BannerGuidance,
  BannerHeader,
  BannerIcon,
  Icon,
  MediaBlockBody,
} from '@trussworks/react-uswds'
import dotGovIcon from '@uswds/uswds/img/icon-dot-gov.svg'
import httpsIcon from '@uswds/uswds/img/icon-https.svg'

export const NewJerseyBanner = () => {
  const { t } = useTranslation('common', { keyPrefix: 'banner' })

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Banner aria-label={t('aria_label')}>
      <BannerHeader
        isOpen={isOpen}
        flagImg={
          ''
          // <BannerFlag
          //   src={`${
          //     process.env.NEXT_PUBLIC_BASE_PATH ?? ''
          //   }/images/nj_state_seal.png`}
          //   alt={t('flag_alt')}
          //   aria-hidden
          //   data-testid="nj-flag-img"
          // />
        }
        headerText={t('header_text')}
        headerActionText={t('header_action')}
      >
        <BannerButton
          isOpen={isOpen}
          aria-controls="gov-banner"
          onClick={() => setIsOpen((previousIsOpen) => !previousIsOpen)}
        >
          {t('header_action')}
        </BannerButton>
      </BannerHeader>
      <BannerContent
        id="gov-banner"
        isOpen={isOpen}
        data-testid="banner-content"
      >
        <div className="grid-row grid-gap-lg">
          <BannerGuidance
            className="tablet:grid-col-6"
            data-testid="dot-gov-guidance"
          >
            <BannerIcon src={dotGovIcon.src} alt="" />
            <MediaBlockBody>
              <p>
                <strong>{t('dot_gov_title')}</strong>
                <br />
                <Trans t={t} i18nKey="dot_gov_description" />
              </p>
            </MediaBlockBody>
          </BannerGuidance>
          <BannerGuidance
            className="tablet:grid-col-6"
            data-testid="https-guidance"
          >
            <BannerIcon src={httpsIcon.src} alt="" />
            <MediaBlockBody>
              <p>
                <strong>{t('https_title')}</strong>
                <br />
                <Trans
                  t={t}
                  i18nKey="https_description_before_icon"
                  components={{
                    strong: <strong />,
                  }}
                />{' '}
                <Icon.Lock aria-label={t('lock_description')} />{' '}
                <Trans
                  t={t}
                  i18nKey="https_description_after_icon"
                  components={{
                    strong: <strong />,
                  }}
                />
              </p>
            </MediaBlockBody>
          </BannerGuidance>
        </div>
      </BannerContent>
    </Banner>
  )
}
